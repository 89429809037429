import React from "react";
import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/patricia-ann-williamson.png";
  const fullName = `Patricia Ann Williamson`;
  const zoomUrl =
    "https://us02web.zoom.us/j/84725776229?pwd=QS95cEszV05aSm9rUUZlbHQyZ2hZUT09";
  const zoomPhoneNumber = "(646) 558-8656";
  const zoomMeetingId = "847 2577 6229";
  const zoomMeetingPasscode = "548306";
  const birthDate = new Date(1934, 12 - 1, 17);
  const deathDate = new Date(2020, 12 - 1, 8);
  const birthDeathFooter = "";
  const memorialDate = new Date(2021, 3 - 1, 13);
  const memorialTime = "10am PST";
  const inviteText = `Please join us in celebrating the life of Patricia Ann Williamson; mother, grandmother, great-grandmother, great-great-grandmother, sister and friend. Share in each other’s prayers, tributes, and memories of a person already missed, but cherished and loved forever.`;
  const inviteChild = <></>;
  const obituary = `Patricia Ann Williamson, 85, passed away peacefully on December 8, 2020 at  her home in Yucaipa, CA after a long and courageous battle with ovarian  cancer. Pat was born during the Great Depression on December 17, 1934 in  Waynoka, Oklahoma to James and Mildred Ripple. Along with her extended  family, she was part of the “Okie Migration” to California in search of a better  way of life and a new beginning. Overcoming and surviving through the difficult  economic times, Pat graduated from King City High School at the top of her  class in 1952. She was admitted to and attended UC Berkeley in 1952-53.`;
  const storiesRecordUrl = "";
  const storiesSubmissionsUrl = "";
  const recordingEmbedDirectUrl = [
    {
      url: "https://storage.googleapis.com/afterword-static/recordings/Patricia%20Williamson%20-%20recording.mp4",
      title: "Memorial Recording",
      poster: "/images/people/patricia-williamson-poster.png",
    },
  ];
  const dropboxUrl = "";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      zoomMeetingPasscode={zoomMeetingPasscode}
      optInviteChild={inviteChild}
      customColumnSpacing="1fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      birthDeathFooter={birthDeathFooter}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
      recordingEmbedDirectUrl={recordingEmbedDirectUrl}
      dropboxUrl={dropboxUrl}
    >
      <p>
        Taking a break from her studies, Pat moved to El Paso, TX where she met
        and married Daniel Mereness Williamson on December 31, 1953. She and
        Dan, a sergeant in the US Army, converted to Catholicism and began to
        raise a family that blossomed to four daughters and four sons.
        Eventually finding their way back to California, Pat resumed her
        education earning both a Bachelor’s and Master’s degree in Clinical
        Psychology, winning the prestigious Most Outstanding Graduate Student
        Award in 1982 from California State University Fullerton. Working as a
        counselor in Atlanta, Georgia, Pat would eventually fulfill her lifetime
        dream of becoming a Doctor when she earned her PhD from American
        University in Washington, DC. After a stop in Provo, UT, she and Dan
        eventually settled in Prescott, AZ where she lived until relocating to
        California in late 2019. As well as being an active member of Sacred
        Heart Church in Prescott, Pat was instrumental in a group that founded
        the Yavapai CASA (Court Appointed Special Advocate) for Kids
        organization in 2003 (
        <a href="//www.yavapaiCASAforkids.org" target="_blank" rel="noreferrer">
          www.yavapaiCASAforkids.org
        </a>
        ).
      </p>
      <p>
        Not only was Pat a devoted wife, a loving mother and grandmother, but
        she was also an accomplished artist expressing herself through her
        painting, sculpting, sewing, baking, and belly dancing. Pat was also an
        avid hiker, successfully hiking the Grand Canyon “Rim-To-Rim” several
        times, the final time in 2015 in celebration of her 80th birthday. She
        will be sorely missed and affectionately remembered. She was preceded in
        death by her husband of 62 years, Daniel and her son Joseph Williamson.
        She is survived by 7 children: Michael (Yvonne) Williamson, Jean
        (Antonio) Arriaga, Teresa Krebs, Mary Bohlmann, John (Becky) Williamson,
        Stephanie (Jon) Tahabsim, and James (Carol) Williamson; 1 sister, Billie
        Jean Tyra; 18 grandchildren; 14 great grandchildren and 2
        great-great-grandchildren, and countless other family and friends. A
        burial Mass at Sacred Heart Church and internment next to her loving
        husband in the National Cemetery in Prescott, AZ will take place in the
        spring of 2021. In lieu of flowers please consider a donation to the
        Yavapai Casa for Kids Foundation.
      </p>
      <p>
        “So now faith, hope, and love abide, these three; but the greatest of
        these is love.” <br />
        (1 Corinthians 13:13)
      </p>
    </StoryPage>
  );
};

export default ThisPage;
